"use strict";

/**/
export function ready(fn) {
  if (document.readyState != 'loading') {
    fn();
  } else {
    if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    } else {
      document.attachEvent('onreadystatechange', function() {
        if (document.readyState != 'loading') {
          fn();
        }
      });
    }
  }
}

/**/
export function hasClass(el, className) {
  if (el.classList) {
    return el.classList.contains(className);
  } else {
    return new RegExp("(^| )" + className + "( |$)", "gi").test(el.className);
  }
}

/**/
export function removeClass(el, className) {
  if (el.classList) {
    el.classList.remove(className);
  } else {
    el.className = el.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), "");
  }
}

/**/
export function addClass(el, className) {
  if (el.classList) {
    el.classList.add(className);
  } else {
    el.className = el.className + ' ' + className;
  }
};

/**/
export function toggleClass(el, className) {
  if (hasClass(el, className)) {
    removeClass(el, className);
  } else {
    addClass(el, className);
  }
};

/**/
export function removeChildren(el) {
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }
}

/*
get query string value
*/
export function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
  return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/**/
export function getElementPostion(el) {
  var x = 0, y = 0;
  while(el != null) {
    x += el.offsetLeft;
    y += el.offsetTop;

    el = el.offsetParent;
  }

  return {
    x: x,
    y: y
  };
}

/*
copy all attributes
*/
export function copyAttributes(from, to, excludeAttr) {
  var excludeAttr = excludeAttr || [],
    attrs = from.attributes;

  for (var i = attrs.length - 1; i >= 0; i--) {
    if (excludeAttr.indexOf(attrs[i].name) > -1) {
      continue;
    }
    to.setAttribute(attrs[i].name, attrs[i].value);
    // to.value = from.value;
  }

  return to;
}

/**/
export function getScrollOffset() {
  var offsetLeft = 0, offsetTop = 0;

  if (document.documentElement.scrollTop) {
    offsetLeft = document.documentElement.scrollLeft;
    offsetTop = document.documentElement.scrollTop;
  } else if (document.body) {
    offsetLeft = document.body.scrollLeft;
    offsetTop = document.body.scrollTop;
  }

  return {
    left: offsetLeft,
    top: offsetTop
  };
};

/**/
export function getElementPosition (el) {
  var offset = getScrollOffset();
  var rect = el.getBoundingClientRect();

  return {
    left: offset.left + rect.left,
    top: offset.top + rect.top,
    bottom: offset.top + rect.top + rect.height
  };
}

/**/
export function getViewportHeight() {
  if (!window.innerHeight) {
    return document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight;
    // return {
    //   w: (document.documentElement.clientWidth ? document.documentElement.clientWidth : document.body.clientWidth),
    //   h: (document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight)
    // };
  } else {
    return window.innerHeight;
    // return {
    //   w: window.innerWidth,
    //   h: window.innerHeight
    // };
  }
};

export function getDocumentHeight() {
  var b = document.body, de = document.documentElement;
  return Math.max(
    b.scrollHeight, de.scrollHeight,
    b.offsetHeight, de.offsetHeight,
    b.clientHeight, de.clientHeight
  );
};

export function skroll(to, duration) {
  var direction, scrollOffset, scrollTop;

  scrollOffset = getScrollOffset();
  scrollTop = scrollOffset.top;

  if (scrollTop == to) {
      return;
  } else if (scrollTop > to) {
    // scroll up
    direction = 'up';
  } else {
    // scroll down
    var maxScrollTop = getDocumentHeight() - getViewportHeight();
    if (maxScrollTop <= to) {
      to = maxScrollTop;
    }
    direction = 'down';
  }

  var distance, step, newDistance, interval, keepScrolling = true;

  distance = to - scrollTop;
  if (duration > 5) {
    step = distance / (duration / 5);
  } else {
    step = distance;
  }

  var scrollstep = function() {
    // body...
  };

  interval = setInterval(function() {

    newDistance = to - (scrollTop + step);
    if (
        (newDistance == 0) ||
        (direction == 'up' && newDistance > 0) ||
        (direction == 'down' && newDistance < 0)
      ) {
      keepScrolling = false
      scrollTop = to;
    } else {
      scrollTop = scrollTop + step;
    }

    window.scrollTo(0, scrollTop);
    if (!keepScrolling) {
      clearInterval(interval);
    }
  }, 5);
}

ready(function() {
	var menuTogglers = document.querySelectorAll('[data-menu]');
	Array.prototype.forEach.call(menuTogglers, function(toggler) {
		// var
		// 	menu = document.getElementById(toggler.dataset.menu),
		// 	pairs = document.querySelectorAll('[data-menu-pair=' + toggler.dataset.menu + ']');

		toggler.addEventListener('click', function(e) {
			e.preventDefault();
			toggleClass(document.body, 'open');
			// toggleClass(menu, 'open');
			// Array.prototype.forEach.call(pairs, function(content) {
			// 	toggleClass(content, 'open');
			// });
		});
	});
});